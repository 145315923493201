<template>
  <div class="about">
    <h2>openZIM CMS</h2>
    <p>
      The openZIM CMS is the publishing counter-part of the <a
        target="_blank"
        href="https://farm.openzim.org"
      >Zimfarm</a>. Zimfarm allows non-tech people to manage ZIM creation ; CMS is a Web tool allowing non-tech people to handle publishing of ZIM files.
    </p>

    <p>Publishing is all about managing the central catalog/library: how it is presented and where it is made available. It is <strong>neither about creation nor distribution</strong>.</p>

    <h2>Supporting Us</h2>
    <p>
      It is <strong>entirely and only supported by donations</strong>. A few dollars may not mean much for you but will certainly help us a lot : if you can, <a
        target="_blank"
        href="https://support.kiwix.org/"
      >consider supporting us</a> - even with the price of a cup of coffee (we love coffee and at times need quite a bit of it).
    </p>

    <p>
      The CMS is a Python/JS project hosted <a
        target="_blank"
        href="https://github.com/openzim/cms"
      >on Github</a>.
    </p>
  </div>
</template>

<script type="text/javascript">
export default {
  name: 'SupportUs'
}
</script>

<style type="text/css">
  .about h2 img {
    width: 2rem;
    height: 2rem;
    margin-bottom: .5rem;
  }
</style>
