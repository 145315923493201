<template>
  <div>
    <div>
      <h2>{{ ident }}</h2>
    </div>
    <div>
      <table class="table table-hover">
        <tbody v-if="title_metadata">
          <tr
            v-for="(value, key) in title_metadata"
            :key="value"
          >
            <td>
              {{ key }}
            </td>
            <td v-if="key.startsWith('Illustration_')">
              <img
                :src="'data:image/png;base64,' + value"
                alt="base64 image"
              >
            </td>
            <td
              v-else
              class="text-break"
            >
              {{ value }}
            </td>
          </tr>
          <tr>
            <td>Books</td>
            <td>
              <ul
                v-for="book in title_books"
                :key="book"
              >
                <li>
                  <router-link :to="{ name: 'book', params: { id: book.id } }">
                    {{ book.name }} &mdash; {{ book.id }}
                  </router-link>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ErrorMessage
      v-if="error"
      :message="error"
    />
  </div>
</template>

<script>
import Common from '../Common.mixin.js'
import ErrorMessage from '../components/ErrorMessage.vue'

export default {
  name: 'TitleDetail',
  components: {
    ErrorMessage
  },
  mixins: [Common],
  props: {
    ident: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      title_languages: null,
      title_tags: null,
      title_metadata: null,
      title_books: null,
      error: null // error message generated by API
    }
  },
  created () {
    const parent = this
    this.startLoading()

    const url = `/titles/${this.ident}`

    this.queryAPI('GET', url)
      .then(function (response) {
        parent.title_languages = response.data.languages
        parent.title_tags = response.data.tags
        parent.title_metadata = response.data.metadata
        parent.title_books = response.data.books
        parent.endLoading()
      })
      .catch(function (error) {
        parent.standardErrorHandling(error)
        parent.endLoading()
      })
  }
}
</script>
