<template>
  <div class="container">
    <table
      v-if="titles"
      class="table"
    >
      <thead>
        <tr>
          <th>Title</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="title in titles"
          :key="title.ident"
        >
          <td>
            <router-link :to="{ name: 'title', params: { ident: title.ident } }">
              {{ title.ident }}
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
    <ErrorMessage
      v-if="error"
      :message="error"
    />
  </div>
</template>

<script>
import Common from '../Common.mixin.js'
import ErrorMessage from '../components/ErrorMessage.vue'

export default {
  name: 'TitlesListing',
  components: {
    ErrorMessage
  },
  mixins: [Common],
  data () {
    return {
      titles: null,
      error: null // error message generated by API
    }
  },
  created () {
    const parent = this
    this.startLoading()

    const url = '/titles'

    this.queryAPI('GET', url)
      .then(function (response) {
        parent.titles = response.data.items
        parent.endLoading()
      })
      .catch(function (error) {
        parent.standardErrorHandling(error)
        parent.endLoading()
      })
  }
}
</script>
