<template>
  <div>
    <div>
      <table class="table table-hover">
        <tbody v-if="book">
          <tr>
            <td>Id</td>
            <td>{{ book.id }}</td>
          </tr>
          <tr>
            <td>Article Count</td>
            <td>{{ book.article_count }}</td>
          </tr>
          <tr>
            <td>Media Count</td>
            <td>{{ book.media_count }}</td>
          </tr>
          <tr>
            <td>Counter</td>
            <td>
              <pre>{{ book.counter }}</pre>
            </td>
          </tr>
          <tr>
            <td>Period</td>
            <td>{{ book.period }}</td>
          </tr>
          <tr>
            <td>Size</td>
            <td>{{ $filters.bytesToHumanReadable(book.size) }}</td>
          </tr>
          <tr>
            <td>Url</td>
            <td>
              <a :href="book.url">{{ book.url }}</a>
            </td>
          </tr>
          <tr>
            <td>Metadata</td>
            <td>
              <table>
                <tbody>
                  <tr
                    v-for="(value, key) in book.metadata"
                    :key="value"
                  >
                    <td>
                      {{ key }}
                    </td>
                    <td v-if="key.startsWith('Illustration_')">
                      <img
                        :src="'data:image/png;base64,' + value"
                        alt="base64 image"
                      >
                    </td>
                    <td
                      v-else
                      class="text-break"
                    >
                      {{ value }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ZimCheck
      v-if="book"
      :book="book"
    />
    <ErrorMessage
      v-if="error"
      :message="error"
    />
  </div>
</template>

<script>
import Common from '../Common.mixin.js'
import ErrorMessage from '../components/ErrorMessage.vue'
import ZimCheck from '../components/ZimCheck.vue'

export default {
  name: 'BookDetails',
  components: {
    ErrorMessage, ZimCheck
  },
  mixins: [Common],
  props: {
    id: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      book: null,
      error: null // error message generated by API
    }
  },
  created () {
    const parent = this
    this.startLoading()

    const url = `/books/${this.id}`

    this.queryAPI('GET', url)
      .then(function (response) {
        parent.book = response.data
        parent.endLoading()
      })
      .catch(function (error) {
        parent.standardErrorHandling(error)
        parent.endLoading()
      })
  }

}
</script>
